
import React from 'react'


import Header from './Header'
import {BrowserRouter , Routes, Route} from 'react-router-dom';
import Footer from './Footer';
import PreLoader from './PreLoader';
import Terms from './terms&policies/Terms';
import Privacy from './terms&policies/Privacy';
import ScrollToTop from '../ScrollTop';
import Refund from './terms&policies/Refund';
const Home =React.lazy(()=>import('./Home'))
const Cultural =React.lazy(()=>import('./Cultural'))
const Contact = React.lazy(()=>import('./Contact'))
const MedTour =React.lazy(()=>import('./MedTour'))
const LazyHotels =React.lazy(()=>import('./Hotels'))
const LazyActivities =React.lazy(()=>import('./Activity'))
const LazyFests =React.lazy(()=>import('./Fests'))
const Payment = React.lazy(()=>import('./Payment'))

const Main = () => {
  //  const[loading,setLoading]=useState(true);
  //  const spinner= document.getElementById("spinner");
  //  if(spinner){
  //   setTimeout(()=>{
  //     spinner.style.display ='none';
  //     setLoading(false);
  //   }, 2000);
  //  }

  return (
   
      <BrowserRouter>
      <ScrollToTop/>
      <Header/>
      <Routes>
        
        <Route path='/' element={<React.Suspense fallback={<PreLoader/>}><Home/></React.Suspense>}></Route>
        <Route path='/activities' element={<React.Suspense fallback={<PreLoader/>}><LazyActivities/></React.Suspense>}></Route>
        <Route path='/hotels' element={<React.Suspense fallback={<PreLoader/>}><LazyHotels/></React.Suspense> }></Route>
        <Route path='/fests' element={<React.Suspense fallback={<PreLoader/>}><LazyFests/></React.Suspense> }></Route>
        <Route path='/contactus' element={<React.Suspense fallback={<PreLoader/>}><Contact/></React.Suspense>}></Route>
        <Route path='/medicaltourism' element={<React.Suspense fallback={<PreLoader/>}><MedTour/></React.Suspense>}></Route>
        <Route path='/culturaltourism' element={<React.Suspense fallback={<PreLoader/>}><Cultural/></React.Suspense>}></Route>
        <Route path='/terms' element={<React.Suspense fallback={<PreLoader/>}><Terms/></React.Suspense>}></Route>
        <Route path='/privacy' element={<React.Suspense fallback={<PreLoader/>}><Privacy/></React.Suspense>}></Route>
        <Route path='/refund-policy' element={<React.Suspense fallback={<PreLoader/>}><Refund/></React.Suspense>}></Route>
        <Route path='/payment-page' element={<React.Suspense fallback={<PreLoader/>}><Payment/></React.Suspense>}></Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
    )
   
   
    
  
}

export default Main
